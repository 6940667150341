<template>
  <div class="error-page">
    <div class="error-img">
      <img src="../../assets/images/500.png" />
    </div>
    <span class="error-explain">哎呀，服务器出了点小问题！</span>
  </div>
</template>

<script>
export default {
  components: {},
  name: '',
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/css/error.scss';
</style>
